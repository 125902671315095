%primary-btn {
  height: 36px;
  border-radius: 4px;
  cursor: pointer;
  white-space: nowrap;
  font-size: 12px;

  &:focus {
    outline: none;
  }
}

%tag-btn {
  border-radius: 4px;
  line-height: 1;
  height: 40px;
  text-align: left;
  font-size: 12px;
}

@mixin tab-btn($bg_color) {
  @if $bg_color == tab-active {
    .tab-active {
      background: $tabBtnFillColor;
      color: var(--linkColor);
      border: 0px;
      font-family: Font-Medium, sans-serif;
      @extend %tag-btn;

      &:hover,
      &:active,
      &:focus {
        background: $tabBtnFillColor;
        color: var(--linkColor);
        border: 0px;
      }
    }
  }
  @if $bg_color == tab-inactive {
    .tab-inactive {
      background: $tabBtnColor;
      color: $baseTextColor;
      border: 0px;
      @extend %tag-btn;

      &:hover {
        background: $tabBtnFillColor;
        color: var(--linkColor);
        border: 0px;
      }
    }
  }
}

@mixin btn($bg_color) {
  @if $bg_color == fill {
    .fill {
      box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
      background: var(--btnGradient) !important;
      color: $white;
      font-family: Font-Semibold, sans-serif;
      @extend %primary-btn;

      &:hover,
      &:focus,
      &:active {
        background: var(--bgBtnFillHover) !important;
        color: $white !important ;
        border-color: var(--bgBtnFillHover) !important;
      }
    }
  }

  @if $bg_color == outline {
    .outline {
      border: 1px solid var(--linkColor);
      color: var(--linkColor) !important;
      font-family: Font-Semibold, sans-serif;
      @extend %primary-btn;

      &:hover,
      &:focus,
      &:active {
        background: var(--btnGradient) !important;
        color: $white !important;
        border-color: var(--btnGradient) !important;
      }
    }
  }
}

.ant-btn:focus,
.ant-btn:hover {
  color: $lightBlue;
  border-color: $lightBlue;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:active,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:hover {
  background: var(--linkColor);
  color: $white;
  border-color: var(--linkColor);
  opacity: 0.4;
}
.ant-btn[disabled],
.ant-btn[disabled]:active,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:hover {
  background: var(--linkColor) !important;
  color: $white !important;
  border-color: var(--linkColor);
  opacity: 0.4;
}

//Shadow-btn
.shadow-btn {
  border: 1px solid #f5f9ff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
}
