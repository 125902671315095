@import "../../assets/sass/variables";
.setting-form {
  &-group {
    gap: 45px;
  }
  &-card {
    grid-template-columns: 1fr 50px;
    align-items: center;
    gap: 20px;
    border-radius: 3px;
    border: 1px solid #e7ecf4;
    background: #fff;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
    padding: 10px 15px;
  }
  &-btn {
    margin-top: 28px;
  }
  &-input {
    width: 50px;
    justify-content: center;
  }
  &-text {
    justify-content: flex-start;
    padding: 10px;
  }
  &-input,
  &-text {
    border-radius: 3px;
    border: 1px solid #e7ecf4;
    background: #fff;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    height: 40px;
  }
  &-metadata {
    grid-template-columns: 1fr 50px;
    gap: 40px;
  }
}
.box-group {
  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 15px;
    &-item {
      margin-right: 0;
    }
    .ant-checkbox {
      margin-left: auto;
      & + span {
        order: -1;
      }
    }
  }
}
.setting-table {
  .ant-table .ant-table-thead > tr > th,
  .ant-table .ant-table-tbody > tr > td {
    border-right: none;
    &:last-child {
      width: 110px;
    }
  }
}
.box-group {
  padding: 20px;
  border-radius: 3px;
  border: 1px solid #e7ecf4;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
  &.column-right-50 {
    grid-template-columns: 1fr 50px;
    gap: 30px;
  }
  &-input {
    border-radius: 3px;
    border: 1px solid #e7ecf4;
    background: #fff;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    height: 40px;
    width: 50px;
    justify-content: center;
    color: #721ead;
  }
}
.setting-generative {
  gap: 50px;
  grid-template-columns: 220px 1fr;
}

.user-preference {
  p {
    font-family: font-Bold, sans-serif;
    font-size: 16px;
    margin-bottom: 10px;
    color: $tabdetail;
  }
}

.document-ingestion {
  gap: 70px;
  margin: 15px 0 30px 0;

  .chunking-strategy,
  .data-embedding {
    p {
      font-size: 14px;
      font-family: "Font-Medium", Arial, Sans-Serif;
      margin: 10px 0 12px 0;
    }
  }
}

.query-settings {
  .system-messages {
    h4 {
      margin-bottom: 20px;
    }
    &-content {
      display: grid;
      grid-template-columns: 30% 1fr;
      gap: 20px;
      margin-bottom: 15px;
      .grid {
        gap: 10px;
      }
    }
  }
  .box-group {
    padding: 10px;
    .ant-input {
      font-size: 14px;
      font-family: Font-Semibold, sans-serif;
      color: var(--linkColor);
      &[disabled] {
        background: $white;
        opacity: 40%;
      }
    }
  }

  .h-44 {
    padding: 10px;
  }
  .error {
    font-family: Font-Medium;
    margin-top: 0px;
    color: $red;
  }
  .caution-icon {
    width: 14px;
  }
}

.project-list {
  .ant-table-container table .ant-table-cell:nth-child(3) {
    text-align: center;
  }
  .ant-table {
    .ant-table-tbody {
      tr {
        td:nth-child(1) {
          color: var(--linkColor);
          font-family: Font-Medium;
        }
      }
    }
  }
}

.disabled-row {
  opacity: 0.6;
}