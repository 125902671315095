body {
  .ant-input-affix-wrapper-lg {
    padding-block: 12.5px;
  }

  .ant-input,
  .ant-input-affix-wrapper,
  .ant-select-selector,
  .ant-picker-outlined {
    border-radius: 3px !important;
    color: $geneis;
    border: 1px solid $borderColor;
    box-shadow: none;
    font-size: 12px;
    font-family: "Font-Regular", Arial, Sans-Serif;
  }
  .ant-input-affix-wrapper {
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.05);
  }

  .ant-form-item-label > label,
  .form-label {
    font-family: "Font-Medium", Arial, Sans-Serif;
    font-weight: 500 !important;
  }
  .ant-picker-outlined {
    width: 100%;
  }
  .ant-input-focused,
  .ant-input:focus,
  .ant-picker-outlined:focus,
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper:hover {
    border-color: $borderColor;
    box-shadow: none;
  }
  .ant-picker-outlined {
    &:focus-within {
      border-color: $borderColor;
      box-shadow: none;
    }
  }
  .ant-input,
  .ant-picker-outlined {
    &:hover {
      border-color: $borderColor;
    }
  }
  .ant-checkbox-group {
    row-gap: 10px;
  }

  .ant-checkbox-wrapper {
    font-size: 12px;
    color: $geneis;
    font-family: "Font-Regular", Arial, Sans-Serif;
  }
  .ant-checkbox .ant-checkbox-inner {
    border-color: rgba(50, 50, 166, 0.5);
  }
  .ant-input::placeholder {
    color: #bfbfbf;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .ant-form-item-label > label {
    font-family: "Font-Medium", Arial, Sans-Serif;
    color: $geneis;
  }
}

//ant switch

.ant-switch {
  &.ant-switch-checked {
    background: $success;
    &:hover:not(.ant-switch-disabled) {
      background: $success;
    }
  }
}
//ant radio
.ant-radio-wrapper {
  &:hover {
    .ant-radio-inner {
      border-color: $linkColor;
    }
  }
  .ant-radio-checked {
    .ant-radio-inner {
      border-color: $linkColor;
      background-color: $linkColor;
    }
  }
}
