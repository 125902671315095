@use "../../../../assets/sass/variables";
.attachment {
  .ant-upload .ant-upload-btn {
    border-radius: 3px;
  }

  .ant-upload-wrapper .ant-upload-list {
    display: flex;
    gap: 10px;
    position: relative;
    width: 100%;
    height: 40px;
    text-align: center;
    background: rgba(0, 0, 0, 0.02);
    border: 1px dashed #d9d9d9;
    border-top: none;
    border-radius: 3px;
    cursor: pointer;
    transition: border-color 0.3s;
    padding: 10px;
  }

  .ant-upload-list-item {
    background-color: #FFFFFF;
    height: 100%;
    margin: 0px !important;
  }

  &-content {
    display: flex;
    gap: 20px;

    &-text {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      margin-top: 8px;
    }
  }

  &-uploadbtn {
    display: flex;
    justify-content: space-between;
  }
}

.annotation-upload {
  .ant-upload.ant-upload-drag {
    min-height: 400px;
    display: flex;
    align-items: center;
  }
}
.status {
  border: 1px solid #f5f9ff;
  border-radius: 3px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
  display: inline-block;
  font-family: Font-Medium, sans-serif;
  font-size: 12px;
  padding: 6px 10px;
}
.filled-folder {
  svg {
    width: 20px;
    height: 20px;
    color: var(--titleColor);
  }
}
.file-folder-name {
  display: flex;
  align-items: center;
  gap: 5px;


  .folder-icon {
    font-size: 18px;
    transition: all 300ms ease;

    &-open {
      display: none;
    }

    &-closed {
      display: inline-block;
    }
  }

  &:hover .folder-icon {
    &-open {
      display: inline-block;
    }

    &-closed {
      display: none;
    }
  }
}

.files-table {
  &-fileFolder{
    width: 38%;
  }
  &-createdOn{
    width: 20%;
  }
  &-updatedOn{
    width: 20%;
  }
  &-status{
    width: 15%;
  }
  &-action{
    width: 7%;
  }
}