.ai-select {
  font-size: 12px;
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: $baseTextColor;
    border: 1px solid $borderColor;
    border-radius: 4px;
    height: 40px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  }

  .ant-select-arrow {
    color: #475569;
    margin-top: -2px;
  }

  &.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    padding-left: 0px;
  }

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: $baseTextColor;
  }
  &.ant-select:hover .ant-select-clear {
    opacity: 0;
  }
  &.ant-select:hover{
    .ant-select-arrow{
      opacity: 1;
    }
  }

  &.ant-select-single .ant-select-selector .ant-select-selection-item,
  &.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 38px;
  }

  &.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: var(--linkColor);
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: var(--linkColor);
  }
}

.form-select {
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: 0px 0px 26px rgb(0 0 0 / 5%) !important;
    height: 44px;
    margin-top: 5px;
    border: 0.5px solid #c4c4c4;
  }

  &.ant-select-single .ant-select-selector .ant-select-selection-item,
  &.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 42px;
  }

  .ant-select-arrow {
    top: 54%;
  }
}

.ant-input {
  border-radius: 3px;
  color: $baseTextColor;
  border: 1px solid $borderColor;
  font-size: 12px;
  padding: 10px;
  box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.05);
}

.ant-input-focused,
.ant-input:focus,
.ant-input:hover {
  border-color: var(--linkColor) !important;
  box-shadow: none !important;
}
.ant-select{
  &:hover, .ant-select-focused{
    .ant-select-selector {
      border-color: var(--linkColor) !important;
      box-shadow: none !important;
    }
  }
  &.ant-select-focused{
    .ant-select-selector{
      
    border-color: var(--linkColor) !important;
    box-shadow: none !important;
    }
  }
}
// -----Form fields-------

// .ant-select:not(.ant-select-customize-input) .ant-select-arrow {
//   left: auto;
//   color: $baseColor;
// }
