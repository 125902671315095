@import "../../../assets/sass/variables";

.source-modal {
  .ant-modal-body {
    padding: 0;
    height: 90vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &.title {
    margin-right: 0px;
    padding: 15px 30px;
    border-bottom: 1px solid $borderColor;
    background: var(--btnGradient);

    h1 {
      color: $white;
      font-size: 14px;
      margin: 0;
    }
  }

  &.content {
    display: flex;
    height: calc(100% - 50px); 
    //gap: 20px;
  }

  &.pdf-viewer {
    flex: 65%;
    overflow-y: auto;
  }

  &.chat-aside {
    flex: 35%;
    overflow-y: auto;
    padding-right: 20px;
  }
}