.provider-contract-document-details {
  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    gap: 20px;
    .label {
      width: 30%;
      text-align: flex-start;
      margin-left: 10px;
      flex-shrink: 0;

      &:first-child {
        margin-left: 0;
      }
    }

    .value {
      width: 70%;
      text-align: flex-start;
      margin-left: 10px;

      &:first-child {
        margin-left: 0;
      }
    }
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
  }
  &.file-details-container {
    display: flex;
    align-items: stretch;

    .react-pdf__Document {
      height: 72vh;
      overflow: auto;
    }

    .tabView {
      height: 100%;

      .tab-container {
        flex-grow: 1;
      }

      .ant-tabs {
        flex-grow: 1;
      }

      .ant-tabs-content,
      .ant-tabs-tabpane-active {
        height: 100%;
      }

      .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #3232a6;
      }

      .ant-tabs-nav {
        position: sticky;
        top: 0;
        z-index: 1;
      }

      .ant-tabs-nav-operations {
        display: none;
      }

      .ant-tabs-tab {
        flex: 1;
        justify-content: center;
      }

      .ant-tabs-nav-wrap {
        justify-content: left !important;
        background-color: white;
      }

      .ant-tabs-ink-bar {
        height: 5px;
        background: none !important;
      }

      .ant-tabs-nav {
        border-bottom: 2px solid #d6d6d6;
      }

      .ant-tabs-tab {
        margin: 0 !important;
        padding: 10px 15px;

        &-active {
          border-bottom: 2px solid var(--linkColor);
        }
      }

      &-title {
        color: var(--linkColor);
      }
    }
  }
}

.close-icon-single-doc {
  position: absolute;
  right: 0;
  z-index: 2;
  top: 10px;
  cursor: pointer;
}
