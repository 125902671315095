.single-doc-qa-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .ant-table-thead{
        background: #F7F7F9;
    }
    .pagination {
        align-self: flex-end;
    }
}
