@use "../../../assets/sass/variables";



.ask-question {
  display: flex;
  gap: 15px;
  height: calc(100vh - 236px);
  position: relative;

  &-empty {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);

    img {
      max-width: 20vw;
    }
  }

  &.collapse {
    .ask-question-right-holder {
      flex-basis: 0;
      padding: 0;
      border-right: 0;
    }
  }
  .ant-btn-primary {
    background: transparent;
  }
  .record-search {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    .ant-input {
      height: 42px;
      font-size: 14px;
      &-search-button {
        height: 42px;
        &:hover{
          border-color: var(--linkColor) !important;
          color: var(--linkColor) !important;
        }
      }
    }
  }
  &-left-conatiner {
    flex: 1 1 70%;
    display: flex;
    flex-direction: column;
  }
  .ask-question-right-container {
    flex: 1;
    h4 {
      line-height: 1;
    }
  }
  .ask-question-icon {
    padding-top: 3px;
    width: 12px;
    height: 15px;
    align-self: center;
  }
  .overlap-icon {
    align-items: center;
    display: flex;
    height: 70px;
    position: absolute;
    left: -22px;
    top: 50%;
    width: 22px;
    border: 1px solid #e7ecf4;
    border-radius: 30px 0 0 30px;
    background: #fff;
    border-right: 0;
    justify-content: center;
  }
  &-right-holder {
    position: relative;
    flex-basis: 30%;
    gap: 10px;
    display: flex;
    align-items: baseline;
    transition: all 0.5s ease;
    border: 1px solid #e7ecf4;
    border-radius: 4px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
    padding: 20px;

    .question-item {
      padding: 12px;
      border-radius: 4px;
      background: rgba($color: #f5f5f6, $alpha: 0.4);
      &-list {
        max-height: calc(100vh - 340px);
        height: 100%;
        overflow-y: auto;
      }
    }
  }
  &-search-bar {
    margin-top: auto;
    &-container {
      grid-template-columns: 1fr 50px;
      gap: 10px;
      .ant-btn-icon-only.ant-btn-lg {
        border-radius: 4px;
      }
      .ant-btn[disabled] {
        background-color: variables.$white;
      }
    }
  }
  &-result {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    &:not(:last-child) {
      margin-bottom: 30px;
      padding-right: 6px;
    }

    .question-icon {
      width: 32px;
      height: 32px;
      padding: 4px;
      border: 1px solid var(--linkColor);
      border-radius: 4px;

      path {
        fill: var(--linkColor);
      }
    }

    &-answer {
      display: grid;
      grid-template-columns: 34px 1fr;
      column-gap: 20px;
      padding: 16px 22px;
      color: variables.$baseTextColor;
    }

    &-ques {
      border: 1px solid variables.$CardBorderColor;
      border-radius: 10px;
      background-color: #F7F7F9;
      width: max-content;
      padding: 16px 22px;
      margin-left: auto;
      h4,p{
        max-width: 30vw;
      }
    }

    &-answer {
      white-space: pre-line;
      background: rgba($color: #f5f5f6, $alpha: 0.2);
      border-radius: 10px;

      &-img {
        width: 32px;
        height: 32px;
        padding: 7px 6px;
        background: var(--linkColor);
        border-radius: 4px;
      }

      p {
        font-size: 14px;
        line-height: 20px;
        color: variables.$baseTextColor
      }

      svg {
        width: 1.25rem;
      }
    }
  }
  &-buttons {
    display: flex;
    align-items: flex-end;
    height: 100px;
    margin: 10px;
    button {                                                                         
      display: flex;
      align-items: center;
      flex-direction: column;
      text-wrap: wrap;
      width: 50%;
      height: 70%;
      margin: 10px;
      border: none;
      color: black !important;
    }
    &-title {
      font-weight: 400;
      font-size: 18px;
      padding-bottom: 5px;
    }
    &-subtitle {
      font-weight: 400;
      font-size: 12px;
    }
    
  }
}

.chat-history-ques {
  border-radius: 3px;
  background: #FFF;
  margin: 4px 0;
  padding: 8px;
  color: var(--linkColor);
  width: 100%;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.source-list {
  margin-top: 6px;
  padding-left: 18px;
  max-height: 142px;
  overflow-y: auto;
  font-family: "Font-Semibold";

  li {
    list-style-type: disc;
    margin: 2px 0;
    padding-right: 20px;
    font-size: 12px;
    
    &.active {
      color: var(--linkColor);
    }
  }

  &.gp-x-10 {
    column-gap: 10px;
  }
}

.source-content {
  margin: 20px 0 0;
  display: flex;
  flex-direction: column;
  gap: 10px;

  div {
    overflow-y: auto;
    font-size: 14px;
  }

  a {
    font-family: font-bold, sans-serif;
  }
  .bg-yellow {
    background-color: yellow;
  }
}

.card-container {
  display: flex;
  position: relative;
  padding: 8px;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.close-button {
  cursor: pointer;
}

.qa-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  position: relative;
}

.project-select {
  display: flex;
  flex-direction: column;
  gap: 20px;
}


.ask-auto {
  &-button {
      border-radius: 50%;
      background-color: variables.$darkBlue;
      padding: 8px;
      transition: all 300ms ease;
      cursor: pointer;
      color: variables.$white;
      font-size: 18px;

      &:hover {
          background-color: variables.$white;
          color: variables.$darkBlue
      }
  }

  &-container {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: stretch;
      height: 100%;

      .ant-drawer-body {
          display: flex;
          flex-direction: column;
      }

      .responses {
          flex-grow: 1;

          display: flex;
          flex-direction: column-reverse;
          overflow-y: auto;
          align-items: stretch;
          gap: 10px;

          margin-bottom: 10px;

          &-single {
              display: flex;
              flex-direction: column;
          }

          .new-question {
              margin: auto;
              text-align: center;
              width: 80%;
              display: flex;
              flex-direction: column;
              gap: 10px;

              p {
                  color: variables.$textSecondaryColor;
                  font-size: 12px;
              }
          }

      }


      form {
          justify-self: flex-end;
          display: flex;
          align-items: stretch;
          gap: 20px;

          border-radius: 5px;
          border: 1px solid variables.$borderColor;
          padding: 10px;
          box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);


          input {
              flex-grow: 1;
              outline: none;
              border: none;

              &:disabled {
                  background-color: transparent;
                  cursor: not-allowed;
              }
          }

          button {
              border-radius: 50%;
              padding: 8px;
              transition: all 300ms ease;
              cursor: pointer;
              color: variables.$darkBlue;
              border: none;
              background-color: transparent;

              &:hover {
                  background-color: variables.$borderColor;
              }

              &:disabled {
                  background-color: variables.$borderColor;
              }

          }
      }

  }

  &-warning {
      color: variables.$textSecondaryColor;
      font-size: 12px;
      text-align: center;
      margin-top: 10px;
  }

  &-prompts-container {
      display: flex;
      flex-direction: column;
      position: relative;
  }

  &-prompts {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 500px));
      gap: 20px;
      margin: 20px 0;
      text-align: center;
      justify-content: center;

      &-single {
          border: 1px solid variables.$lightgray;
          border-radius: 10px;
          padding: 10px;
          cursor: pointer;

          &:hover {
              background-color: variables.$lightgray;
          }

          p {
            color: var(--linkColor);
            font-size: 14px;
          }

          i {
              font-size: 12px;
          }

      }

      &-toggle {
          position: absolute;
          top: -10px;
          left: 50%;
          transform: translateX(-50%);
          border-radius: 20px 20px 0 0;
          padding: 3px 25px;
          border: 1px solid variables.$lightgray;
          font-size: 12px;
          transition: all 250ms ease;
          background-color: #fff;

          &:hover {
              background-color: variables.$lightgray;
          }

          &-short {
              top: -20px;
          }
      }
  }
}

.alert-center {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 100%; 
  

  .ant-alert-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: #ff4d4f;
  }

  .ant-alert-message {
    flex: none;
    text-align: center;
    margin-left: 8px; 
  }

  .alert-icon {
    color: darkred;
  }
}

.ask-auto-prompts-single{
  line-height: 1.5;
  height: 58px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.chat-history-toggle {
  position: absolute;
  top: 38%;
  transform: rotate(270deg);
  border-radius: 20px 20px 0 0;
  padding: 3px 25px;
  border: 1px solid #ededed;
  font-size: 12px;
  transition: all 250ms ease;
  cursor: pointer;
  right: -14px;
  background-color: variables.$white;
  border-bottom: none;
  z-index: 1;
  &:hover {
    background-color: variables.$white;
  }
  &-long {
    right: auto;
    left: -20px;
  }
}

.chat-aside {
  margin-left: 20px;
  flex-grow: 1;
  height: 100%;
  overflow: auto;
  background-color: #ffffff;
  padding: 1.875rem;
  border-left: 2px solid #f5f9ff;
}

.left-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  position: relative;
  width: 100%;
}

.source-number{
  color: var(--linkColor);
}