@use "../../assets/sass/variables";

.text-editor {
  h3 {
    font-size: 14px;
    font-family: Font-Medium, sans-serif;
    margin-bottom: 14px;
    padding: 0px;
  }

  // h3::before {
  //   display: inline-block;
  //   margin-right: 4px;
  //   color: #ff4d4f;
  //   font-size: 14px;
  //   font-family: SimSun, sans-serif;
  //   line-height: 1;
  //   content: "*";
  // }

  // .quill {
  //   border: 0.5px solid #c4c4c4;
  //   box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.05);
  // }

  .ql-editor {
    overflow-y: auto;
    min-height: 200px;
    max-height: 300px;
  }

  .ql-snow {
    .ql-stroke {
      stroke: #a6a6a6;
    }
    .ql-picker {
      color: #a6a6a6;
      &.ql-expanded {
        .ql-picker-label {
          color: var(--linkColor);

          .ql-stroke {
            stroke: var(--linkColor);
          }
        }
      }
    }
    &.ql-toolbar {
      .ql-picker-item:hover {
        color: #111;

        .ql-stroke {
          stroke: #111;
        }
      }
    }
  }

  .ql-snow .ql-fill,
  .ql-snow .ql-stroke.ql-fill {
    fill: #a6a6a6;
  }

  .ql-toolbar.ql-snow {
    box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.05);
    border: 0.5px solid #c4c4c4;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border-bottom: 0px;

    .ql-picker {
      &.ql-expanded {
        .ql-picker-options {
          border-radius: 6px;
          margin-top: 3px;
        }
        .ql-picker-label {
          border-color: transparent;
        }
      }
    }
  }

  .ql-snow.ql-toolbar .ql-picker-label.ql-active {
    color: var(--linkColor);
  }

  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke {
    stroke: var(--linkColor);
  }

  .ql-container.ql-snow {
    border: 0.5px solid #c4c4c4;
    border-radius: 0 0px 4px 4px;
    font-size: 14px;
    color: variables.$baseTextColor;
    box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.05);

    .ql-editor.ql-blank::before {
      color: variables.$baseTextColor;
      font-size: 14px;
    }
  }

  .ql-snow.ql-toolbar {
    .ql-picker-label {
      &:hover {
        color: var(--linkColor);
        stroke: var(--linkColor);

        .ql-stroke {
          color: var(--linkColor);
          stroke: var(--linkColor);
        }
      }
    }

    .ql-formats button {
      &.ql-bold {
        &:hover,
        &.ql-active {
          svg path {
            stroke: var(--linkColor);
          }
        }
      }

      &.ql-italic {
        &:hover,
        &.ql-active {
          svg line {
            stroke: var(--linkColor);
          }
        }
      }

      &.ql-underline {
        &:hover,
        &.ql-active {
          svg rect {
            fill: var(--linkColor);
          }

          svg path {
            stroke: var(--linkColor);
          }
        }
      }

      &.ql-strike {
        &:hover,
        &.ql-active {
          svg line {
            stroke: var(--linkColor);
          }

          svg path {
            fill: var(--linkColor);
          }
        }
      }

      &.ql-script {
        &:hover,
        &.ql-active {
          svg path {
            fill: var(--linkColor);
          }
        }
      }

      &.ql-header {
        &:hover,
        &.ql-active {
          svg path {
            fill: var(--linkColor);
          }
        }
      }

      &.ql-blockquote {
        &:hover,
        &.ql-active {
          svg rect,
          path {
            fill: var(--linkColor);
            stroke: var(--linkColor);
          }
        }
      }

      &.ql-code-block {
        &:hover,
        &.ql-active {
          svg polyline,
          line {
            fill: var(--linkColor);
            stroke: var(--linkColor);
          }
        }
      }

      &.ql-list {
        &:hover,
        &.ql-active {
          svg line,
          path {
            fill: var(--linkColor);
            stroke: var(--linkColor);
          }
        }
      }

      &.ql-indent {
        &:hover,
        &.ql-active {
          svg line,
          polyline {
            fill: var(--linkColor);
            stroke: var(--linkColor);
          }
        }
      }

      &.ql-direction {
        &:hover,
        &.ql-active {
          svg line,
          path,
          rect {
            fill: var(--linkColor);
            stroke: var(--linkColor);
            color: var(--linkColor);
          }

          svg polygon {
            fill: var(--linkColor);
            stroke: var(--linkColor);
          }
        }
      }

      &.ql-link {
        &:hover,
        &.ql-active {
          svg line,
          path {
            stroke: var(--linkColor);
            color: var(--linkColor);
          }
        }
      }

      &.ql-image {
        &:hover,
        &.ql-active {
          svg rect {
            stroke: var(--linkColor);
          }

          svg polyline {
            fill: var(--linkColor);
          }

          svg circle {
            fill: var(--linkColor);
          }
        }
      }
    }
  }
}
