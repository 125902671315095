@import "../../assets/sass/variables";

.dataset-from {
  &.ant-form {
    max-width: 680px;
    margin-inline: auto;
  }
  &-note {
    font-size: 12px;
    font-style: italic;
  }
}
.dataset-connect {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 54px;
  padding-inline: 20px;
  .ant-card {
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
    height: 170px;
    width: 220px;
    padding: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border-color: #e7ecf4;
    position: relative;
  }
}
.tab-upload {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
  .ant-upload.ant-upload-drag {
    border: none;
  }
  & > span {
    display: flex;
    .ant-upload-drag {
      flex-basis: 50%;
    }
    .ant-upload-list {
      padding: 16px 35px;
      flex-basis: 50%;
      border-left: 1px dashed #d9d9d9;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      &-item:hover {
        .ant-upload-list-item-card-actions-btn {
          border: none;
        }
      }
      &-item-info {
        padding: 5px;
      }
      &-text-container {
        width: 100%;
        text-align: left;
      }
      &-item-card-actions {
        margin-left: auto;
      }
    }
  }
  &-box {
    padding-inline: 35px;
    gap: 12px;
    svg {
      width: 60px;
      height: 52px;
    }
  }
}
.dataset-upload-table {
  h2 {
    font-family: "Font-Semibold", Arial, Sans-Serif;
    font-size: 16px;
    color: $tabdetail;
  }
  // .ant-table {
  //   .ant-table-tbody {
  //     max-height: calc(100vh - 650px);
  //   }
  // }
  & > div {
    margin: 16px 0;
  }
}

.disabled-card {
  opacity: 0.5;
  cursor: not-allowed; /* Show "not-allowed" cursor when hovering */
}

.hover-border:hover {
  border: 2px solid green;
}
.green-border {
  border: 2px solid green !important;
}
.cover-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.main-image {
  width: 120px !important;
  max-height: 100px !important;
  object-fit: contain;
}

.small-image {
  position: absolute;
  top: 5px;
  object-fit: contain;
  height:22px;
  right: 5px;
}

.alert-center {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;

  .ant-alert-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: #ff4d4f;
  }

  .ant-alert-message {
    flex: none;
    text-align: center;
    margin-left: 8px;
  }

  .alert-icon {
    color: darkred;
  }
}
