@use "../sass/variables";
@import "../sass/mixins";

.flex {
  display: flex;
  flex-wrap: wrap;
}
.cursor-pointer {
  cursor: pointer;
}
.font-Semibold {
  font-family: "font-Semibold", sans-serif;
}
.font-Medium {
  font-family: "font-Medium";
}
.grid {
  display: grid;
}
.grid-cols-2 {
  grid-template-columns: repeat(2, 1fr);
}
.grid-cols-3 {
  grid-template-columns: repeat(3, 1fr);
}
.no-wrap {
  flex-wrap: nowrap;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

.aie {
  align-items: flex-end;
}

.jcc {
  justify-content: center;
}

.jce {
  justify-content: end;
}

.asc {
  align-self: center;
}

.jcsb {
  justify-content: space-between;
}

.flex-column {
  flex-direction: column;
}

.w-full {
  width: 100%;
}

.w-45 {
  width: 45%;
}

.w-50 {
  width: 50%;
}
.w-75 {
  width: 75%;
}
.h-full {
  height: 100%;
}

.m-0 {
  margin: 0;
}

.m-t {
  margin-top: 30px;
}
.m-b {
  margin-bottom: 12px;
}

.mb-0 {
  margin-bottom: 0;
}

.f100 {
  flex-basis: 100%;
}
.opacity-0 {
  opacity: 0;
}
.fauto {
  flex: auto;
}

.pr {
  position: relative;
}

.pa {
  position: absolute;
}

.tc {
  text-align: center;
}

.tr {
  text-align: right;
}
.tl {
  text-align: left;
}
.gp-10 {
  gap: 10px;
}

.gp-40 {
  gap: 40px;
}

.gp {
  gap: 20px;
}

.italic {
  margin-top: 2px;
  font-size: 12px;
  font-style: italic;
}

.bd-tp {
  color: rgba($baseColor, 0.7);
  font-family: Font-Regular, sans-serif;
  padding-top: 20px;
  border-top: 1px solid rgba($borderColor, 0.2);
}

@include btn(fill);
@include btn(outline);
@include tab-btn(tab-active);
@include tab-btn(tab-inactive);

.custom-icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.error {
  font-size: 12px;
  color: #ff4d4f;
  margin-top: 6px;
}

/*switch*/
.ant-switch {
  background: $borderColor;
  &-checked {
    background: var(--linkColor);
    &:focus {
      box-shadow: none;
    }
  }
  &-handle:before {
    background: $baseColor;
  }
}

/* Scroll*/

.scrollBar {
  max-height: 400px;
  overflow-y: auto;
  display: block;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ececec;
  // border: 0.3px solid #919191;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ececec;
}

.ant-progress-bg,
.ant-progress-success-bg {
  background-color: $green;
}

.ant-progress-inner {
  background-color: $borderColor;
}

.ant-table-filter-dropdown {
  display: none;
}
.ant-table-filter-trigger:hover {
  color: #bfbfbf;
  background: transparent;
}
.ant-table-filter-trigger {
  cursor: default;
}
.ant-notification-notice {
  border-radius: 6px;
}

.ant-notification-notice-with-icon .ant-notification-notice-description {
  font-size: 16px;
  line-height: 1.3;
  margin-left: 40px;
}
.ant-notification-notice-close {
  right: 10px;
  top: 21px;
}

.ant-empty-normal {
  height: 40px;
}

.ant-empty-normal .ant-empty-image {
  display: none;
}

.ant-empty-description {
  display: none;
}

// cursor pointers class
.cursor-pointer {
  cursor: pointer;
}
.cursor-disable {
  cursor: not-allowed;
}

.normal-font-size {
  font-size: 1rem;
  font-family: Font-Regular, sans-serif;
  line-height: 1.5rem;
}

.border-bottom {
  border-bottom: 2px solid variables.$CardBorderColor;
}

.p-10 {
  padding: 10px;
}
.p-15 {
  padding: 15px 20px;
}
.p-20 {
  padding: 20px;
}
.pb-0 {
  padding-bottom: 0;
}
.p-block {
  padding: 15px 0;
}

.pt-5 {
  padding-top: 5px;
}

.table-heading {
  @include font_size_color(14px, variables.$tabdetail, null, null);
  font-family: Font-Medium, sans-serif;
}
.text_ellipsis {
  @include text_ellipsis;
}
.basic-detail-content {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 15px rgb(0 0 0 / 5%);
}

.white-box-bg {
  padding: 24px;
  border-radius: 3px;
  border: 1px solid $CardBorderColor;
  background: $white;
  box-shadow: 0px 4px 15px 0px $boxShadowColor;
}

.tab-content-scroll {
  overflow: auto;
  max-height: calc(100vh - 210px);
}

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}
.font-16 {
  font-size: 16px;
}

.mt-auto {
  margin-top: auto;
}
.mb-40 {
  margin-bottom: 40px;
}
.lh-1 {
  line-height: 1;
}

.link-color {
  color: var(--linkColor);
}
.ask-auto {
  width: 20px;
  height: 24px;
  margin-bottom: 5px;
}

.rotate-180 {
  transform: rotate(180deg);
}

/* Border Radius */
.rounded-none {
  border-radius: 0;
}
.rounded-sm {
  border-radius: 2px;
}
.rounded-md {
  border-radius: 4px;
}
.rounded-lg {
  border-radius: 8px;
}
.rounded-xl {
  border-radius: 12px;
}
.rounded-full {
  border-radius: 50%;
}

/* Overflow */
.overflow-auto {
  overflow: auto;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-visible {
  overflow: visible;
}
.overflow-scroll {
  overflow: scroll;
}

/* Font Weight */
.font-light {
  font-weight: 300;
}
.font-normal {
  font-weight: 400;
}
.font-medium {
  font-weight: 500;
}
.font-semibold {
  font-weight: 600;
}
.font-bold {
  font-weight: 700;
}

/* Font Size */
.text-xs {
  font-size: 12px;
}
.text-sm {
  font-size: 14px;
}
.text-md {
  font-size: 16px;
}
.text-lg {
  font-size: 18px;
}
.text-xl {
  font-size: 20px;
}

/* Padding */
.p-5 {
  padding: 5px;
}
.p-10 {
  padding: 10px;
}
.p-20 {
  padding: 20px;
}
.pt-5 {
  padding-top: 5px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-20 {
  padding-top: 20px;
}
.pb-5 {
  padding-bottom: 5px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pl-5 {
  padding-left: 5px;
}
.pl-10 {
  padding-left: 10px;
}
.pl-20 {
  padding-left: 20px;
}
.pr-5 {
  padding-right: 5px;
}
.pr-10 {
  padding-right: 10px;
}
.pr-20 {
  padding-right: 20px;
}

/* Margin */
.m-5 {
  margin: 5px;
}
.m-10 {
  margin: 10px;
}
.m-20 {
  margin: 20px;
}
.mt-5 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}
.ml-5 {
  margin-left: 5px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-20 {
  margin-left: 20px;
}
.mr-5 {
  margin-right: 5px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-15 {
  margin-right: 10px;
}
.mr-20 {
  margin-right: 20px;
}
.my-5 {
  margin: 5px 0px;
}
.my-10 {
  margin: 10px 0px;
}
.my-15 {
  margin: 15px 0px;
}
.my-20 {
  margin: 10px 0px;
}

/* Text Alignment */
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

/* Flexbox */
.flex-row {
  flex-direction: row;
}
.flex-col {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-nowrap {
  flex-wrap: nowrap;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.items-center {
  align-items: center;
}
.items-baseline {
  align-items: baseline;
}
.items-stretch {
  align-items: stretch;
}

/* Display */
.d-block {
  display: block;
}
.d-inline-block {
  display: inline-block;
}
.d-inline {
  display: inline;
}
.d-flex {
  display: flex;
}
.d-grid {
  display: grid;
}
.d-none {
  display: none;
}

// Reusable classes for color
.text-primary {
  color: variables.$textPrimaryColor;
}
.text-secondary {
  color: variables.$textSecondaryColor;
}
.text-base {
  color: variables.$baseTextColor;
}
.text-title {
  color: var(--titleColor);
}
.text-sm-title {
  color: variables.$smTitleColor;
}

.text-link {
  color: var(--linkColor);

  &:hover {
    color: var(--likHoverColor);
  }
}
.text-theme {
  color: var(--textThemeColor);
}

// Reusable classes for background color
.bg-primary {
  background-color: variables.$primaryColor;
}
.bg-orange {
  background-color: variables.$orange;
}
.bg-red {
  background-color: variables.$red;
}
.bg-blue {
  background-color: variables.$blue;
}
.bg-green {
  background-color: variables.$green;
}
.bg-lightgray {
  background-color: variables.$lightgray;
}
.bg-tabdetail {
  background-color: variables.$tabdetail;
}
.bg-base {
  background-color: variables.$bgBaseColor;
}
.bg-white {
  background-color: variables.$white;
}
.bg-gradient {
  background: var(--bgGradient);
}
.bg-gradient-x {
  background: var(--bgGradientX);
}
.bg-gradient-sider {
  background: var(--bgGradientSider);
}
.bg-btn-hover {
  background-color: var(--bgBtnHover);
}
.bg-tab {
  background-color: var(--tabBgColor);
}
.bg-dark-purple {
  background-color: variables.$darkPurpleColor;
}

//Resuable classses for text wrap
.text-wrap {
  text-wrap: wrap;
}
.text-nowrap {
  text-wrap: nowrap;
}

//Resuable classes for word break
.word-break-all {
  word-break: break-all;
}

// body .chat-skeleton {
//   --paragraph-top: 20px;
//   --paragraph-list: 9px;
//   --title: 36px;
//   --list-top: 30px;
//   .ant-skeleton-avatar {
//     width: 36px !important;
//     height: 36px !important;
//     border-radius: 3px !important;
//     background: #e7e1f5 !important;
//   }
//   .ant-skeleton-title,
//   .ant-skeleton-paragraph li {
//     background: #e7e1f5 !important;
//   }
// }

.msg {
  display: flex;
  gap: 20px;
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  &-container {
    height: 100%;
    width: 100%;
  }

  &-wrapper {
    --totalHeight: 81px; // height of page header and spacing
    height: calc(100% - var(--totalHeight));
    flex: 1;
  }

  &-scroller {
    display: flex;
    overflow-y: auto;
    padding: 10px;
    cursor: pointer;
    flex-direction: column-reverse;
    overflow-anchor: auto;
    max-height: 100%;
  }
  &-img {
    width: 36px;
    height: 36px;
    background-color: #300c6a;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    overflow: hidden;
  }
  &-content {
    flex: 1;
  }
  &-right {
    flex-direction: row-reverse;
  }
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
