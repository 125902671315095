@import "../../assets/sass/variables";

.autonomizeModal {
  .pd-t-0 {
    padding-top: 0px !important;
  }
  .ant-modal-content {
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
    border-radius: 4px;

    .ant-modal-body {
      padding: 0px;
    }

    .ant-modal-header {
      display: none;
    }

    .ant-modal-footer {
      display: none;
    }

    .modal-form-content {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

  .ant-modal-close {
    padding: 6px;
    &-x {
      color: $baseColor;
      font-size: 20px;
      // &:hover {
      //   color: $purpleColor;
      // }
    }
  }

  .ant-row {
    display: block;
  }

  .ant-form-item {
    margin-bottom: 30px;
    &-label > label {
      color: $baseTextColor;
      font-size: 14px;
      font-family: Font-Medium, sans-serif;
    }
  }

  .ant-input,
  .ant-input-affix-wrapper {
    border-radius: 3px;
    color: $baseTextColor;
    font-size: 14px;
    margin-top: 5px;
    padding: 10px;
    border: 0.5px solid #c4c4c4;
    box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.05);
  }

  .ant-input-focused,
  .ant-input:focus {
    border-color: var(--linkColor);
  }
  .ant-input:hover {
    border-color: var(--linkColor);
  }

  .ant-form-item:last-child {
    padding-top: 20px;
  }
  .auto-complete {
    .ant-select {
      width: 100%;
      margin-bottom: 30px;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      padding: 10px 6px;
      height: 44px;
      border-radius: 3px;
      border: 1px solid #c4c4c4;
      margin-top: 12px;
      box-shadow: 0px 0px 26px rgb(0 0 0 / 5%);
    }
    .ant-select-single:not(.ant-select-customize-input)
      .ant-select-selector
      .ant-select-selection-search-input {
      height: 40px;
    }
  }
  &.edit-project {
    .addClient-content {
      max-height: calc(100vh - 150px);
      overflow-y: auto;
    }
  }

  .add-new-insight {
    .ant-form-item:last-child {
      text-align: right;
    }
  }

  .deleteConfirmation {
    .aiButton {
      min-width: 121px;
      max-width: 121px;
    }
  }
  .ant-input[disabled] {
    background: $baseColor;
    opacity: 0.5;
  }
}
.ant-modal-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.ant-modal {
  top: initial;
  padding: 10px 0;
}

.select-options{
  margin-top:26px;
  .ant-checkbox-wrapper+.ant-checkbox-wrapper{
    margin-left: 0px;
  }
  .ant-checkbox-wrapper{
    margin-top:10px;
  }
}