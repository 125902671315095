@import "./variables";
@import "./mixins";

.task-info {
  margin-top: 15px;
  h4 {
    color: var(--linkColor);
    margin-bottom: 8px;
  }
  &-title {
    margin-bottom: 10px;
    @include font_size_color(14px, $blackColor, null, null, Font-Medium);
  }
  &-description {
    @include font_size_color(null, $baseTextColor, null, null, Font-Regular);
  }
  &-collapse {
    .ant-collapse {
      background: $baseColor;
      border-color: $borderColor;
      border-radius: 4px;
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
      .ant-collapse {
        &-item {
          border-color: $borderColor;
        }
      }
      &-header {
        font-weight: 600;
        color: $bgBaseColor;
      }
      &-content {
        border-color: $borderColor;
        &-box {
          font-size: 12px;
          ul {
            padding-left: 15px;
            li {
              list-style-type: disc;
              margin-top: 5px;
            }
          }
          .task-desc-bg {
            background: rgba($color: #eee, $alpha: 0.3);
            padding: 10px;
            border-radius: 4px;
            margin: 10px 0 20px 0;
            h5 {
              margin: 20px 0 10px 0;
              color: $tabdetail;
              &:first-child {
                margin: 0;
              }
            }
            .m-10 {
              margin-top: 8px;
            }
            &-heading {
              font-family: Font-Semibold, sans-serif;
            }
          }
        }
      }
    }
  }
}

.disclaimer {
  ul {
    max-height: 120px;
    overflow-y: auto;
    padding-left: 15px;
    li {
      list-style-type: disc;
      margin-bottom: 5px;
    }
  }
}

.query-response {
  border: 1px solid $borderColor;
  background: $baseColor;
  padding: 16px;
  border-radius: 3px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
}
