@font-face {
  font-family: Font-Regular;
  src: url(../fonts/Inter-Regular.woff2);
  font-display: swap;
}

@font-face {
  font-family: Font-Medium;
  src: url(../fonts/Inter-Medium.woff2);
  font-display: swap;
}

@font-face {
  font-family: Font-Semibold;
  src: url(../fonts/Inter-SemiBold.woff2);
  font-display: swap;
}

@font-face {
  font-family: Font-Bold;
  src: url(../fonts/Inter-Bold.woff2);
  font-display: swap;
}
@import url("https://fonts.googleapis.com/css2?family=Damion&display=swap");
html {
  font-size: 12px;

  @media only screen and (min-width: 1600px) {
    font-size: 12px;
  }
}

body {
  font-size: 12px;
  font-family: "Font-Regular", Arial, Sans-Serif;
  // background: $bgColor;
}

h1 {
  font-family: "Font-Semibold", Arial, Sans-Serif;
  font-size: 18px;
  color: $tabdetail;
  // color: $baseColor;
  // font-weight: normal;
}

h2 {
  font-family: "Font-Medium", Arial, Sans-Serif;
  font-size: 20px;
  // color: $baseColor;
}

h3 {
  font-family: "Font-Medium", Arial, Sans-Serif;
  font-size: 14px;
  color: $baseTextColor;
}

h4 {
  font-family: "Font-Semibold", Arial, Sans-Serif;
  font-size: 14px;
  color: $tabdetail;
}

h5 {
  font-family: "Font-Semibold", Arial, Sans-Serif;
  font-size: 13px;
  color: $baseTextColor;
}
a {
  color: var(--linkColor);
  &:hover {
    color: var(--linkColor);
  }
}

p {
  color: $baseTextColor;
}

.select-field-title {
  font-family: Font-Medium, sans-serif;
}

.bold {
  font-family: "Font-Bold", Arial, Sans-Serif;
}
.text-geneis {
  color: #475569;
}
.text-textfield {
  color: #e7ecf4;
}
.text-link {
  color: #3232a6;
}
.text-headline {
  color: #575985;
}
.text-title {
  color: #1e293b;
}

.text-12 {
  font-size: 12px;
}
.text-14 {
  font-size: 14px;
}

.font-medium {
  font-weight: 500;
}
.font-semibold {
  font-weight: 600;
}
.text-center {
  text-align: center;
}
