.project-sub-heading{
    display: flex;
    align-items: center;
    background: #F7F7F9;
    height: 47px;
    border: 1px solid #E7ECF4;
    border-top: none;
    color: #575985;
    padding-left: 19px;
    span{
        display: flex;
        flex-direction: row;
        p{
            font-weight: 500;
            padding-left:5px;
        }
    }
}