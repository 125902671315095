.reactMarkDown {
  margin-top: 20px;
  ol,
  ul {
    margin-top: 1em;
    margin-bottom: 1em;
    list-style: disc outside none;
    li {
      margin-left: 2em;
      display: list-item;
      text-align: -webkit-match-parent;
      margin-bottom: 5px;
      line-height: 1.4;
    }
  }
  p {
    font-size: 14px;
    font-weight: 400;
    white-space: pre-wrap;
    margin-bottom: 5px;
    line-height: 1.4;
  }
  & > h4 {
    margin-top: 10px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 10px;
    tr:not(.thead) {
      border-bottom: 1px solid #dddde1;
    }
    th,
    td {
      padding-block: 12px;
      text-align: left;
    }
    td:first-child {
      padding-right: 20px;
    }
  }
}

.provider-contract {
  // overflow-y: auto;
  // padding: 1.875rem;
  // border-left: 2px solid #f1eef8;
  // margin-bottom: 6px;
  border-bottom-left-radius: 10px;
  .prompt-container {
    border: 1px solid #ededed;
    border-radius: 10px;
    color: #3232a6;
    padding: 10px;
    text-align: center;
    line-height: 1.7;
    min-height: 58px;
    flex: 1;
    cursor: pointer;
    &:hover {
      background-color: #ededed;
    }
  }
  .record-search {
    .ant-btn {
      background-color: #fff;
      border-color: #d9d9d9;
      &:hover {
        background-color: #fff;
        border-color: #1677ff;
      }
      &[disabled] {
        background-color: #fff !important;
      }
      svg {
        color: #1677ff;
      }
    }
    .ant-input {
      &:hover {
        z-index: 1 !important;
      }
    }
    .ant-input-search-button {
      height: 41px;
    }
  }
  .prompt-toggle {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 20px 20px 0 0;
    padding: 3px 25px;
    border: 1px solid #ededed;
    font-size: 12px;
    transition: all 250ms ease;
    background-color: #fff;
  }
  .btn-disable {
    opacity: 0.5;
    pointer-events: none;
  }

  .msg-container .ant-input-focused,
  .msg-container .ant-input:focus,
  .msg-container .ant-input:hover {
    border-color: #4096ff !important;
  }
  .msg-container .ant-input:focus {
    box-shadow: 0 0 0 2px #3232a633 !important;
  }
  .sidebar-icon {
    svg {
      color: #756e88;
    }
    &:hover {
      svg {
        color: #000;
      }
    }
  }
  .send-btn {
    color: var(--linkColor) !important;
    &:hover {
      border-color: transparent;
      background-color: transparent !important;
      color: var(--linkColor) !important;
    }
  }
  .reactMarkDown:not(:last-of-type) {
    margin-bottom: 20px;
  }
}
.provider-contract-prompts {
  .msg-wrapper {
    height: calc(100% - 171px);
  }
}

.evidence-grid {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(250px, 1fr)
  ); /* Adjust width based on your needs */
  gap: 20px;
  margin: 20px 0;
}

.evidence-item {
  border: 1px solid #ddd;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.evidence-heading {
  font-weight: bold;
  margin-bottom: 10px;
}

.evidence-content {
  font-size: 14px;
  line-height: 1.6;
}

.table-custom {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 16px;
  text-align: left;
  background-color: #f9f9f9;
}

/* Styling for table headers */
.table-custom th {
  background: #3232a6;
  color: white;
  padding: 10px;
  border: 1px solid #ddd;
}

/* Styling for table cells */
.table-custom td {
  padding: 10px;
  border: 1px solid #ddd;
}

/* Alternate row shading */
.table-custom tr:nth-child(even) {
  background-color: #f2f2f2;
}

/* Hover effect for rows */
.table-custom tr:hover {
  background-color: #ddddf0;
}
