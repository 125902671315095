@use "../../../assets/sass/variables";

.disable-link {
  pointer-events: none;
}

.ant-layout-sider-trigger {
  margin-bottom: 10px !important;
  width: auto !important;
  background: transparent !important;
  svg {
    width: 17px;
    height: 17px;
  }
}

.sidebar {
  .logo {
    margin-bottom: 40px;
  }
  &.ant-layout-sider {
    padding: 25px;
    background: var(--bgGradientX);
    height: 100%;
  }

  &-nav {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    padding-right: 5px;

    &-link {
      position: relative;
      color: rgba(variables.$white, 0.6);
      font-family: Font-Medium;

      &.active,
      &:hover {
        color: var(--sidebarLinkActive);
      }

      svg {
        width: 17px;
        height: 17px;
      }
      .chat-icon {
        width: 21px;
        height: 21px;
      }
    }
  }
}