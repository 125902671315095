@use "../../assets/sass/variables";

.askqa-container {
  display: flex;
  height: 100%;
  position: relative;
  .sources-containers {
    padding: 20px;
  }
}
.left-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  position: relative;
  width: 100%;
}

.analysis-container {
  width: 100%;
  margin: 0 auto;
  max-height: 100px;
  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .label {
      width: 30%;
      text-align: flex-start;
      margin-left: 10px;

      &:first-child {
        margin-left: 0;
      }
    }

    .value {
      width: 70%;
      text-align: flex-start;
      margin-left: 10px;

      &:first-child {
        margin-left: 0;
      }
    }
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
  }
  .download-btn {
    height: 50px !important;
    width: 50px !important;
    position: fixed;
    bottom: 64px;
    right: 31px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 100% !important;
    color: white;
  }
}
.ant-tabs > .ant-tabs-nav,
.ant-tabs > div > .ant-tabs-nav {
  display: block !important; /* to stop the tabs from flickering */
}

.chat-history-toggle {
  position: absolute;
  top: 38%;
  transform: rotate(270deg);
  border-radius: 20px 20px 0 0;
  padding: 3px 25px;
  border: 1px solid #ededed;
  font-size: 12px;
  transition: all 250ms ease;
  cursor: pointer;
  right: -14px;
  background-color: variables.$white;
  border-bottom: none;
  z-index: 1;
  &:hover {
    background-color: variables.$white;
  }
  &-long {
    right: auto;
    left: -20px;
  }
}

.chat-aside {
  margin-left: 20px;
  flex-grow: 1;
  height: 100%;
  overflow: auto;
  background-color: #ffffff;
  padding: 1.875rem;
  border-left: 2px solid #f5f9ff;
}

.single-doc-ask-auto-prompts {
  grid-template-columns: 1fr 1fr;
}

.autonomize-logo {
  width: 200px;
  height: auto;
}

.single-doc-prompts-height{
  height: 50px !important;
}

.source-number{
  color: var(--linkColor);
}