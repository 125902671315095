.expand-toggle-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 5px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    cursor: pointer;

    &.LTR {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
    }
}