// .light-mode{
$primaryColor: #e8ae55;
$textPrimaryColor: #ffffff;
$textSecondaryColor: #9e9e9e;
$orange: #d87e14;
$red: #c2334d;
$blue: #4743db;
$green: #46d143;
$lightgray: #ededed;
$tabdetail: #1e293b;
$bgBaseColor: #222328;
$baseColor: #ffffff;
$baseTextColor: #475569;
$titleColor: #301934;
$smTitleColor: #02462d;
$borderColor: #e7ecf4;
$CardBorderColor: #f5f9ff;
$accordianBg: #f7f7f9;
$borderBottomColor: #e8e8e8;
$tabBtnColor: #fcfcfc;
$tabBtnFillColor: #ebf1fa;
$selectRowColor: #f9fbff;
$blackColor: #0f172a;
$darkBlue: #3232a6;
$purpleColor: #8626c0;
$prussianBlueColor: #410a63;
$linkColor: #3232a6;
$brand: #5f3cab;
$geneis: #475569;
$greenNew: #60b769;
$outline: #d7e0e6;
$bodytext: #445460;
$headline: #575985;
$menu: #756e88;
$lightgrey: #f7f7f9;
$textfield: #e7ecf4;
$success: #43bf7c;
$info: #3cc2e7;
$error: #dd5757;
$title: #1e293b;
$neworange: #e36305;

$white: #fff;
// $linkColor: #3232a6;
$tabLink: #333333;
$tabLinkHover: rgb(50 50 166 / 69%);
// $bgGradient: linear-gradient(135deg, #7904a3 0%, #270067 56.12%);
// $bgGradientX: linear-gradient(180deg, #270067 48.96%, #7904a3 100%);
// $bgGradientSider: linear-gradient(180deg, #018994 27.92%, #48abb9 100%);
// $btnGradient: linear-gradient(90deg, #3232a6 0%, #8626c0 100%);
// $bgBtnHover: #8626c0;
// $loginBtnColor: #fff;
// $textThemeColor: #8626c0;
// $linkColor: #3232a6;
// $likHoverColor: #8626c0;
// $titleColor: #383048;
$btnTransparent: transparent;
// $tabBgColor: #f5f5f6;
$boxShadowColor: rgba(0, 0, 0, 0.05);
$darkPurpleColor: #721ead;
$lightBlue: #4096ff;
// }
// .molina-light-mode{
//   $primaryColor: #eeb111;
//   // $textPrimaryColor: #ffffff;
//   $textSecondaryColor: #9e9e9e;
//   $orange: #d87e14;
//   $red: #bb4545;
//   $blue: #4743db;
//   $green: #46d143;
//   $lightgray: #ededed;
//   $tabdetail: #1e293b;
//   $bgBaseColor: #333333;
//   $baseColor: #ffffff;
//   $baseTextColor: #635f6a;
//   $titleColor: #301934;
//   $smTitleColor: #02462d;
//   $borderColor: #e7ecf4;
//   $CardBorderColor: #f5f9ff;
//   $tabBtnColor: #fcfcfc;
//   $tabBtnFillColor: #ebf1fa;
//   $selectRowColor: #f9fbff;
//   $blackColor: #0f172a;
//   $darkBlue: #3232a6;
//   // $purpleColor: #8626c0;
//   // $prussianBlueColor: #410a63;

//   $white: #fff;
//   // $linkColor: #3232a6;
//   $tabLink: #333333;
//   // $tabLinkHover: rgb(51 51 51 / 70%);
//   // $bgGradient: linear-gradient(135deg, #7904a3 0%, #270067 56.12%);
//   $bgGradient: linear-gradient(135deg, #009ca7 0%, #027a83 100%);
//   $bgGradientX: linear-gradient(180deg, #027a83 27.92%, #009ba6 100%);
//   $bgGradientSider: linear-gradient(180deg, #018994 27.92%, #48abb9 100%);
//   $btnGradient: linear-gradient(90deg, #027a83 0%, #009ba6 100%);
//   $bgBtnHover: #fff;
//   $loginBtnColor: #027a83;
//   // $textThemeColor: #8626c0;
//   $linkColor: #027a83;
//   $likHoverColor: #009ba6;
//   $titleColor: #383048;
//   $btnTransparent: transparent;
//   $tabBgColor: #f5f5f6;
//   $boxShadowColor: rgba(0, 0, 0, 0.05);
//   $darkPurpleColor: #721ead;
// }

.light-mode {
  --bgGradient: linear-gradient(135deg, #7904a3 0%, #270067 56.12%);
  --bgGradientX: linear-gradient(180deg, #270067 48.96%, #7904a3 100%);
  --bgGradientSider: linear-gradient(180deg, #018994 27.92%, #48abb9 100%);
  --btnGradient: linear-gradient(90deg, #3232a6 0%, #8626c0 100%);
  --bgBtnHover: #8626c0;
  --bgBtnFillHover: #8626c0;
  --loginBtnColor: #fff;
  --loginBtnHoverColor: #8626c0;
  --textThemeColor: #8626c0;
  --linkColor: #3232a6;
  --likHoverColor: #8626c0;
  --titleColor: #383048;
  --tabBgColor: #f5f5f6;
  --tabActiveBgColor: #3232a6;
  --green: #46d143;
  --sidebarLinkActive: #fff;
  --accordionActiveBg: #fff;
  --whiteColor: #fff;
}
.molina-light-mode {
  --bgGradient: linear-gradient(135deg, #009ca7 0%, #027a83 100%);
  --bgGradientX: linear-gradient(180deg, #027a83 27.92%, #009ba6 100%);
  --bgGradientSider: linear-gradient(180deg, #018994 27.92%, #48abb9 100%);
  --btnGradient: linear-gradient(90deg, #027a83 0%, #009ba6 100%);
  --bgBtnHover: #027a83;
  --bgBtnFillHover: #eeb111;
  --loginBtnColor: #fff;
  --loginBtnHoverColor: #027a83;
  --textThemeColor: #8626c0;
  --linkColor: #027a83;
  --likHoverColor: #009ba6;
  --titleColor: #383048;
  --tabBgColor: #f5f5f6;
  --tabActiveBgColor: #eeb111;
  --green: #46d143;
  --sidebarLinkActive: #eeb111;
  --accordionActiveBg: #eeb111;
  --whiteColor: #fff;
}
