@import "../../../../assets/sass/variables";


.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .form-content {
    width: 100%;
  .field-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-bottom: 20px;

    .ant-form-item {
      margin: 0 !important;
      padding: 0 !important;
    }

    .wide-input1,
    .wide-input2 {
      border-radius: 3px;
      border: 1px solid #e7ecf4;
      font-size: 12px;
      padding: 10px;
      box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.05);
    }

    .wide-input2 {
      width: 420px !important;
    }
    .wide-input1,.wide-input2{
      &:focus, &:hover{
        border-color: var(--linkColor);
        outline: none;
      }
    }
  }
}
}



.single-doc {
  margin-bottom: 20px;
  font-size: 14px;
}

.headings {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: start;
  margin-bottom: 20px;
  gap: 20px;

  p {
    margin: 0;
  }
}


.qa-config-footer {
  width: max-content;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  gap:10px;
  margin-bottom: 20px;
  justify-content: flex-end;
}

.input-container {
  display: flex;
  gap: 20px;
}