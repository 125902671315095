@use "../../assets/sass/variables";
@import "../../assets/sass/variables";

.project-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.loading {
  width: 100%;
}

.project-card {
  padding: 15px 20px !important;
  cursor: pointer;
}

.title {
  font-size: 16px;
  font-family: Font-Semibold;
  color: var(--linkColor);
  margin-right: 0.5em;
  margin-bottom: 10px;
}

.description {
  height: 59px;
  margin-bottom: 10px; // Add margin bottom between description and detail
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.detail {
  margin-bottom: 5px; // Add margin bottom between details
}

.highlight {
  font-size: 12px;
  font-weight: bold;
}

.date-container{
  display: flex;
  gap: 20px;
}

.date-container p {
  margin: 0;
  font-size: 12px;
  font-weight: bold;
}

.date {
  font-weight: bolder;
}

.attachment {
  .ant-upload {
    &:hover{
      border-color: var(--linkColor) !important;
    }
    .ant-upload-btn {
      border-radius: 3px;
    }
  }

  .ant-upload-wrapper .ant-upload-list {
    display: flex;
    gap: 10px;
    position: relative;
    width: 100%;
    height: 40px;
    text-align: center;
    background: rgba(0, 0, 0, 0.02);
    border: 1px dashed #d9d9d9;
    border-top: none;
    border-radius: 3px;
    cursor: pointer;
    transition: border-color 0.3s;
    padding: 10px;
  }

  .ant-upload-list-item {
    background-color: #FFFFFF;
    height: 100%;
    margin: 0px !important;
  }

  &-content {
    display: flex;
    gap: 20px;

    &-text {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      margin-top: 8px;
    }
  }

  &-uploadbtn {
    display: flex;
    justify-content: space-between;
  }
}
.annotation-upload {
  .ant-upload.ant-upload-drag {
    min-height: 400px;
    display: flex;
    align-items: center;
  }
}

.status {
  border: 1px solid #f5f9ff;
  border-radius: 3px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
  display: inline-block;
  font-family: Font-Medium, sans-serif;
  font-size: 12px;
  padding: 6px 10px;
}

.filled-folder {
  svg {
    width: 20px;
    height: 20px;
    color: var(--titleColor);
  }
}

.file-folder-name {
  display: flex;
  align-items: center;
  gap: 5px;


  .folder-icon {
    font-size: 18px;
    transition: all 300ms ease;

    &-open {
      display: none;
    }

    &-closed {
      display: inline-block;
    }
  }

  &:hover .folder-icon {
    &-open {
      display: inline-block;
    }

    &-closed {
      display: none;
    }
  }
}

.table-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.table-container .file-table {
  flex: 1;
  overflow-y: auto;
}



.file-detail-container {
  display: flex;
  align-items: stretch;

  .react-pdf__Document {
    height: 72vh;
    overflow: auto;
  }

  .tabView {
    flex: 1.5;
    overflow: auto;
    display: flex;
    flex-direction: column;

    .tab-container {
      flex-grow: 1;
    }

    .ant-tabs {
      flex-grow: 1;
    }

    .ant-tabs-content,
    .ant-tabs-tabpane-active {
      height: 100%;
    }

    .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #3232a6;
    }

    .ant-tabs-nav {
      position: sticky;
      top: 0;
      z-index: 1;
    }

    .ant-tabs-nav-operations {
      display: none;
    }

    .ant-tabs-tab {
      flex: 1;
      justify-content: center;
    }

    .ant-tabs-nav-wrap {
      justify-content: left !important;
      background-color: white;
    }

    .ant-tabs-ink-bar {
      height: 5px;
      background: none !important;
    }

    .ant-tabs-nav {
      border-bottom: 2px solid #d6d6d6;
    }

    .ant-tabs-tab {
      margin: 0 !important;
      padding: 10px 15px;

      &-active {
        border-bottom: 2px solid var(--linkColor);
      }
    }

    &-title {
      color: var(--linkColor);
    }
  }
}

.analysis-container {}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  padding: 10px;
  font-size: 14px;
}

.ant-table .ant-table-tbody {
  max-height: 100% !important;
}

.view-control {
  opacity: 0.2;

  &.active {
    color: var(--linkColor);
    opacity: 1;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.border{
  border:  1px solid black;
}

.project-list {
  .ant-table .ant-table-thead > tr > th,
  .ant-table .ant-table-tbody > tr > td {
    border-right: none;
    text-align: left !important;
    &:last-child {
      width: 150px;
    }
  }
  .ant-table-container {
    width: 100%;
    table {
      width: 100%;
    }
  }
  .ant-table-tbody {
    tr {
      td:nth-child(1) {
        color: var(--linkColor);
        font-family: Font-Medium;
      }
    }
  }
}

.project-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  max-height: 4.5em; 
}