@use "./variables";
@import "./mixins";
// Custmize accordian
.customize-accordion {
  .ant-collapse {
    background: transparent;
    border: none;
    width: 100%;
    .ant-collapse-item > .ant-collapse-header {
      padding: 0 0 10px 0;
      .ant-collapse-header-text {
        @include font_size_color(12px, variables.$baseTextColor, null, null, Font-Semibold);
        background: transparent;
      }
    }
    .ant-collapse-item {
      border: none;
      margin-bottom: 30px;
    }
  }
  .ant-collapse-content {
    border: none;
    .ant-collapse-content-box {
      padding: 0;
    }
  }

  .ant-collapse-content-active {
    .scrollBar {
      max-height: calc(100vh - 370px);
      padding-right: 10px;
    }
  }

  .searches {
    &-li {
      font-size: 12px;
      border-bottom: 1px solid variables.$borderColor;
      line-height: 14px;
      padding: 8px 0;
      &-right {
        gap: 10px;
        opacity: 0.5;
      }
    }
    &-title {
      color: variables.$tabdetail;
      width: calc(100% - 45px);
    }
    &-date {
      color: variables.$baseTextColor;
      margin-top: 5px;
    }
  }
}

.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  transform: translateY(-50%) rotate(90deg) !important;
  opacity: 0.5;
  width: 11px;
  height: 11px;
}

.query-settings {
  .ant-collapse {
    border-radius: 3px;
    background: $white;
    border: 0px solid $CardBorderColor;

    .ant-collapse-expand-icon {
      svg {
        margin-top: 12px;
      }
    }

    > .ant-collapse-item {
      border-bottom: 0px;
      border-radius: 3px;
      border: 1px solid $CardBorderColor;
      margin-bottom: 20px;
    }

    // &-item:not(:last-child){
    //   margin-bottom: 20px;
    // }
    &-item {
      &-active {
        .ant-collapse-header {
          background: var(--accordionActiveBg);
          // color: $white;
        }
      }
    }

    &-header {
      border-radius: 2px 3px 0px 0px;
      // background: $CardBorderColor;
      border: 0px;
      &-text {
        font-family: "Font-Semibold", Arial, Sans-Serif;
      }
    }
    &-content {
      border-radius: 3px;
      border: 1px solid $CardBorderColor;
      background: $accordianBg;
      box-shadow: 0px 4px 15px 0px $boxShadowColor;
      border: 1px solid $borderBottomColor;
      padding: 20px;
      .ant-collapse-content-box {
        padding: 0px;
      }
    }
  }
}
